export default {
    list: {
        columns: [
          { label: '№', key: 'index' },
          { label: 'Нэр', key: 'name' },
          { label: 'Үйлдэл', key: 'actions' }
        ],
        datas: [
          {
            name: 'Олгосон зээлийн тайлан',
            slug: 'take_loan_list',
            config: {
              header: {
                  'Төрөл': 'typeCode',
                  'Овог': 'lastname',
                  'Нэр': 'firstname',
                  'Утас': 'phoneNumber',
                  'Регистрийн дугаар': 'registerNumber',
                  'Зээлийн данс': 'accountNumber',
                  'Огноо': 'loanDate',
                  'Зээлийн хэмжээ': 'loanAmount',
                  'Хүү': 'interest',
                  'Хугацаа /сар/': 'term',
                  'Хаагдсан эсэх': 'isClosed',
                  'Зээлийн үлдэгдэл': 'loanBalance',
                  'Merchant code': 'merchantCode',
                  'Merchant name': 'merchantName',
                  'Бүртгэсэн ажилтан': 'createdBy',
                  'Cунгасан эсэх': 'isExtend'
              }
            }
          },
          {
            name: 'Зээлийн үлдэгдэлийн тайлан',
            slug: 'loan_balance_list',
            config: {
              header: {
                'Овог': 'lastname',
                'Нэр': 'firstname',
                'Утас': 'phoneNumber',
                'Регистрийн дугаар': 'registerNumber',
                'Зээлийн данс': 'accountNumber',
                'Зээл авсан огноо': 'loanDate',
                'Зээлийн дуусах огноо': 'expiryDate',
                'Зээл авсан дүн': 'loanAmount',
                'Хүү': 'interestAmount',
                'Нэмэгдүүлсэн хүү': 'unduelossAmount',
                'Зээлийн үлдэгдэл': 'loanBalance',
                'Merchant code': 'merchantCode',
                'Merchant name': 'merchantName',
              }
            }
          },
          {
            name: 'Эргэн төлөлтийн тайлан',
            slug: 'repayment',
            config: {
              header: {
                'Овог': 'lastname',
                'Нэр': 'firstname',
                'Утас': 'phoneNumber',
                'Регистрийн дугаар': 'registerNumber',
                'Төлсөн огноо': 'transactionDate',
                'Төлсөн дүн': 'amount',
                'Үндсэн зээл': 'principalAmount',
                'Хүү': 'interestAmount',
                'Шимтгэл': 'fee',
                'Merchant name': 'merchantName',
              }
            }
          },
          {
            name: 'Хаагдсан зээлийн тайлан',
            slug: 'closed_loan_list',
            config: {
              header: {
                'Овог': 'lastname',
                'Нэр': 'firstname',
                'Утас': 'phoneNumber',
                'Регистрийн дугаар': 'registerNumber',
                'Зээлийн данс': 'accountNumber',
                'Зээл авсан огноо': 'loanDate',
                'Зээл авсан дүн': 'loanAmount',
                'Хүү': 'interestAmount',
                'Нийт дүн': 'totalAmount',
                'Merchant code': 'merchantCode',
                'Merchant name': 'merchantName',
              }
            }
          },
          {
            name: 'Барьцаа хөрөнгө, даатгалын тайлан',
            slug: 'deposit_insurance',
            config: {
              header: {
                'Овог': 'lastname',
                'Нэр': 'firstname',
                'Утас': 'username',
                'Регистрийн дугаар': 'registerNumber',
                'Зээлийн данс': 'loanAccountNumber',
                'Үйлдвэрлэсэн он': 'depositFactoryYear',
                'Орж ирсэн он': 'depositIncomeYear',
                'Марк': 'depositMark',
                'Улсын дугаар': 'depositCountryCode',
                'Арлын дугаар': 'depositRegistrationCode',
                'Өнгө': 'depositColor',
                'GPS serial': 'depositGpsSerial',
                'Дата сим дугаар': 'depositDataSimNumber',
                'Даатгалын нэр': 'insuranceName',
                'Даатгалын төрөл': 'insuranceType',
                'Эхлэх огноо': 'insuranceStartDate',
                'Дуусах огноо': 'insuranceEndDate',
                'Үнэлгээ хийсэн дүн': 'insuranceEvaluationAmount',
                'Даатгал хийлгэсэн дүн': 'insuranceAmount',
                'Даатгалын хувь ': 'insurancePercent',
                'Ажилтан ': 'sysUserFirstname',
              }
            }
          }
        ]
      }
  }
  